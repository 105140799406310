import { graphql } from "gatsby";

import Link from 'components/link';
import Pager from 'components/pager';
import Content from "components/templates/Content";
import Layout from 'components/templates/Layout';
import Crowdriff from "components/templates/global/Crowdriff";
import { ListingsContext } from 'contexts/Listings';
import truncate from "helpers/truncate";
//import zparse from "helpers/zparse";
import DataProvider from 'providers/data';
import React from "react";

const SimpleViewListingList = props => {
	let { pageInfo } = props.data.allSimpleviewListing;
	let listings = React.useMemo(() => [], []);
	let category = null;
	const { edges } = props.data.allContentPage;
	const nodes = edges.map(({ node }) => node);
	let page = nodes[0];
	const { nodes: categoryNodes } = props.data.allSimpleviewCategory;
	category = categoryNodes[0];

	const customFilterCategories = ['culinary-experiences', 'meetings', 'weddings'];

	listings = props.data.allSimpleviewListing?.nodes ?? [];

	if ((props.providerResponse.data.length > 0 || !!listings !== false) && props.providerStatusText === 'LOADED') {
		listings = props.providerResponse.data;
		pageInfo = {
			hasPreviousPage: (props.providerResponse.page > 1),
			hasNextPage: (props.providerResponse.page < props.providerResponse.pages),
			perPage: props.providerResponse.pageSize,
			currentPage: props.providerResponse.page,
			totalCount: props.providerResponse.total,
			pageCount: props.providerResponse.pages,
		};
	}

	const pattern = ['/listings/$permalink/', '/listings/$permalink/page-$page/'];

	const [displayListings, setDisplayListings] = React.useState(listings)

	React.useEffect(() => {
		setDisplayListings(listings)
	}, [listings])

	return (
		<Layout headerBackgroundImage={category?.photo}>
			<ListingsContext.Provider value={{ listings, setDisplayListings, category }}>
				<Content
					filterKey={customFilterCategories.includes(category?.permalink) ? category?.permalink : "listings"}
					page={page}
					headlineAlignment="center"
					headlineTitle={category?.name}
					headlineBody={category?.longDescription}
					headlineImage={category?.photo}
					headlineSchema="light"
				/>
			</ListingsContext.Provider>
			<section id="listings-results">
				<div className="grid-container">
					<div className="grid-x grid-margin-x small-up-1 medium-up-2 large-up-3">
						{!!displayListings?.length && displayListings.map((listing, index) => (
							<div style={{ marginBottom: "27px" }} key={`${listing.id}-${index}`} className="cell medium-4 text-left" >
								<div className="news-card flex-container flex-dir-column align-items">
									<div className="news-photo" style={{ backgroundImage: `url(${listing.photo})` }}></div>
									<div className="news-card-content">
										<h2 style={{ alignSelf: 'flex-start' }} className="news-title text-center">{listing.companyName}</h2>
										<p className="news-text text-center">{truncate(listing.description, 300)}</p>
									</div>
									<Link className="button" to={`/listing/${listing.permalink}/`}>Read More</Link>
								</div>
							</div>
						))}
					</div>
					{displayListings?.length === 0 && (
						<div className="grid-x grid-margin-x">
							<div className="cell">
								<h2 className="text-center">No current listings. Check back soon!</h2>
							</div>
						</div>
					)}
				</div>
				<div className="grid-container">
					<div className="grid-x grid-margin-x">
						<div className="cell small-12">
							{(pageInfo.pageCount > 1) && <Pager page={pageInfo.currentPage} pages={pageInfo.pageCount} pattern={pattern} pageSize={pageInfo.perPage} total={pageInfo.totalCount} />}
						</div>
					</div>
				</div>
			</section>
			{category?.crowdriff && <Crowdriff hash={category?.crowdriff} />}
		</Layout>
	);
}

const getData = (props) => <DataProvider liveRefresh={props.pageContext.liveRefresh} type="SimpleviewListing" apiParams={{ approved: true, deleted: false, archived: false, _sort: "randSort", _order: "asc", _join: "Category,Neighborhood,SubCategory", "Category.id": props.data.allSimpleviewCategory?.nodes[0]?.id }}><SimpleViewListingList {...props} /></DataProvider>
export default getData;


export { Head } from 'components/templates/Head';

export const query = graphql`
query listingsQuery ($permalink: String, $uri: String) {
	allContentPage(
	  filter: {uri: {eq: $uri}, deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}
	) {
	  edges {
		node {
		  pageTitle
		  meta
		  content {
			main
		  }
		  headlineBody
		  headlineTitle
		  headlineImage
		}
	  }
	}
	allSimpleviewListing(
	  filter: {Category: {elemMatch: {object: {permalink: {eq: $permalink}}}}}
	  sort: {fields: randSort, order: ASC}
	) {
	  nodes {
		Neighborhood {
		  object {
			permalink
		  }
		}
		SubCategory {
		  object {
			permalink
		  }
		}
		id
		photo
		companyName
		description
		permalink
		cuisines
		amenities
		amenityKeys
		mealTypes
		indoorSquareFootage
	  }
	  pageInfo {
		hasNextPage
		hasPreviousPage
		perPage
		currentPage
		totalCount
		pageCount
	  }
	}
	allSimpleviewCategory(
	  filter: {approved: {eq: true}, archived: {eq: false}, deleted: {eq: false}, permalink: {eq: $permalink}}
	  sort: {fields: sort, order: DESC}
	) {
	  nodes {
		id
		name
		photo
		longDescription
		permalink
		crowdriff
		SubCategory {
		  object {
			name
			permalink
		  }
		}
	  }
	}
}
`